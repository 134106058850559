import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import { useState } from "react";
import { Option } from "antd/es/mentions";
import { Helpers } from "../../shared/Helper";
import Repository from "../../helpers/Repository";

export const AddOperationModal = ({ visible, setVisible, onAdd }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOk = () => {
    const data = form.getFieldsValue();
    console.log(data);
    onFinish(data);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    const key = Helpers.makeKey(19);
    message
      .loading({ content: "Adding Operation, please wait!", key, duration: 0 })
      .then();
    Repository.addOperationItem(values)
      .then((res) => {
        message
          .success({
            content: "Operation Added successfully!",
            key,
            duration: 1,
          })
          .then();
        setConfirmLoading(false);
        setVisible(false);
        onAdd();
      })
      .catch((err) => {
        console.log(err);
        const response = err.response;
        if (!response) {
          message.error({ content: "Unexpected error happened", key }).then();
          return;
        }
        if (response.status !== 400) {
          message.error({ content: "Unexpected error happened", key }).then();
          return;
        }
        if (!response.data.message) {
          message.error({ content: "Unexpected error happened", key }).then();
          return;
        }

        message.error({ content: response.data.message, key }).then();
      });
  };

  return (
    <Modal
      title="Title"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            scrollToFirstError
            onFinish={handleOk}
          >
            <Form.Item
              label="Target Id"
              name="targetId"
              rules={[
                {
                  required: true,
                  message: "Id is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Target Id" type={"text"} />
            </Form.Item>
            <Form.Item
              label="Max Count"
              name="count"
              rules={[
                {
                  required: true,
                  message: "Max Count is required",
                },
                {
                  type: "number",
                },
              ]}
              hasFeedback
            >
              <InputNumber
                min={1}
                max={2147483647}
                placeholder="Max Count"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Interval"
              name="interval"
              rules={[
                {
                  required: true,
                  message: "Interval is required",
                },
                {
                  type: "number",
                },
              ]}
              hasFeedback
            >
              <InputNumber
                min={1}
                max={2147483647}
                placeholder="Interval"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Operation"
              name="operation"
              rules={[
                {
                  required: true,
                  message: "Operation is required",
                },
              ]}
              hasFeedback
            >
              <Select placeholder={"Select Operation Type"}>
                <Option value={"pageLike"}>Page Like</Option>
                <Option value={"PostLike"}>Post Like</Option>
                <Option value={"Follow"}>Follow</Option>
                <Option value={"Like"}>Like</Option>
                <Option value={"Love"}>Love</Option>
                <Option value={"Haha"}>Haha</Option>
                <Option value={"Wow"}>Wow</Option>
                <Option value={"Sad"}>Sad</Option>
                <Option value={"Angry"}>Angry</Option>
              </Select>
            </Form.Item>
            {/*<Form.Item>*/}
            {/*    <Button type={"primary"} htmlType="submit">Add</Button>*/}
            {/*</Form.Item>*/}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
