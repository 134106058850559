import { Button, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useRef } from "react";
import Repository from "./../../helpers/Repository";
import { Helpers } from "./../../shared/Helper";

export function UploadTokens({ refreshStatistics }) {
  const inputFile = useRef(null);

  const uploadTokens = (tokensList) => {
    console.log(tokensList);
    const key = Helpers.makeKey(12);
    message
      .loading({
        content: `Uploading ${tokensList.length} tokens...`,
        key,
        duration: 0,
      })
      .then();

    //const tokensToUpload = tokens.filter((a) => a.token);
    Repository.uploadOperationTokens(tokensList)
      .then((res) => {
        if (!res.data) {
          console.log(res.data);
          message.error({ content: "Unexpected error happened", key });
          return;
        }
        message.success({ content: "Uploading done!", key });
        console.log(res);
        refreshStatistics();
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Unexpected error happened", key });
      });
  };

  const onFileChanged = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const tokens = reader.result.split(/\r\n|\n|\r/);
        uploadTokens(tokens);
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      <Button
        type={"primary"}
        icon={<CloudUploadOutlined />}
        onClick={() => inputFile.current.click()}
      >
        Upload Token
      </Button>
      <input
        type={"file"}
        name={"uploader"}
        ref={inputFile}
        multiple={false}
        style={{ display: "none" }}
        onChange={onFileChanged}
      />
    </>
  );
}
