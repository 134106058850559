import {Redirect, Route} from "react-router-dom";
import {useAuth} from "./UseAuth";
import axios from "axios";


export function ProtectedRoute({children, ...rest}) {
    const {isAuthenticated, user} = useAuth();
    if (user) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    }
    console.log(isAuthenticated);
    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}