import {useEffect, useState} from "react";
import Repository from "../../helpers/Repository";
import {LoadingOutlined} from "@ant-design/icons";
import {Button, Popover, Spin, Table} from "antd";
import {Helpers} from "../../shared/Helper";

export function EventLog() {
    Helpers.setTitle("Event Logs");
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    useEffect(() => {
        getEventLogs(1, 10);
    }, [])


    const getEventLogs = (pageIndex, pageSize) => {
        setIsLoading(true);
        setPageSize(pageSize);
        Repository.getAllEventLogs(pageSize, pageIndex).then(res => {
            setTotalCount(res.data.totalCount);
            setLogs(res.data.data);
            console.log(res);

        })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }

    const renderRawObject = (rawObj) =>
        <>
            <Popover content={rawObj} title={"Modified object/message"}>
                <Button type={"link"}  >
                    View
                </Button>
            </Popover>
        </>


    const tableColumns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "User",
            dataIndex: "createdBy",
            key: "fullName",
            render: user => <>{user.fullName}</>
        }, {
            title: "Operation",
            dataIndex: "operation",
            key: "operation"
        }, {
            title: "Object Type",
            dataIndex: "objectType",
            key: "objectType"
        },
        {
            title: "At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: date => <>{new Date(date).toLocaleString()}</>
        },
        {
            title: "Target",
            dataIndex: "objectId",
            key: "objectId",
        },
        {
            title: "Updated Value",
            dataIndex: "rawObject",
            key: "rawObject",
            render: renderRawObject
        }

    ]


    return (

        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
            <Table
                columns={tableColumns}
                dataSource={logs}

                pagination={
                    {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: totalCount,
                        pageSizeOptions: [1, 5, 10, 20, 50, 100],
                        onChange: getEventLogs,
                        showTotal: t => `Total ${t} items`
                    }
                }
            />
        </Spin>

    )
}