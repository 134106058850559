import {
  Button,
  Col,
  message,
  Popconfirm,
  Popover,
  Row,
  Tag,
  Tooltip,
} from "antd";
import { ViewData } from "../../shared/ViewData";
import Repository from "../../helpers/Repository";
import { useEffect, useState } from "react";
import { DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import Paragraph from "antd/es/typography/Paragraph";
import { Helpers } from "../../shared/Helper";

export function TokenOperationItems({
  accessTokens,
  loadingState,
  onChange,
  totalItems,
}) {
  const [tokens, setTokens] = useState([]);
  useEffect(() => {
    setTokens(accessTokens);
  }, [accessTokens]);
  const renderActions = (val, row) => (
    <Row gutter={[24, 24]}>
      {/*Delete Token*/}
      <Col span={6}>
        <Popconfirm title="Are you sure?" onConfirm={() => deleteToken(row.id)}>
          <Tooltip title={"Delete Token"}>
            <Text
              type={"danger"}
              style={{ cursor: "pointer", fontSize: "18px" }}
            >
              <DeleteOutlined />
            </Text>
          </Tooltip>
        </Popconfirm>
      </Col>
      {/*Check Token*/}
      <Col span={6}>
        <Tooltip title={"Check Token"}>
          <Text
            type={"warning"}
            style={{ cursor: "pointer", fontSize: "18px" }}
            onClick={() => checkToken(row)}
          >
            <HistoryOutlined />
          </Text>
        </Tooltip>
      </Col>
    </Row>
  );

  const checkToken = (row) => {
    const key = Helpers.makeKey(17);
    message.loading({
      content: "Checking token, please wait...",
      duration: 0,
      key,
    });
    Repository.checkOperationAccessToken(row.id)
      .then((res) => {
        if (res.data.isSuspended === row.isSuspended) {
          console.log("No changes");
          message.info({ content: "Checking token is done.", key });
          return;
        }
        const index = tokens.findIndex((a) => a.id === row.id);
        console.log(index);
        if (index === -1) {
          console.log("row is not found");
          return;
        }
        const newTokens = [...tokens];
        newTokens[index] = res.data;
        setTokens(newTokens);
        message.info({ content: "Checking token is done.", key });
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Unexpected error happened", key });
      });
  };

  const deleteToken = (id) => {
    const key = Helpers.makeKey(16);
    message.loading({
      content: "Deleting token, please wait...",
      duration: 0,
      key,
    });
    Repository.deleteOperationToken(id)
      .then((res) => {
        if (!res.data) {
          message.error({ content: "Unexpected error happened", key });
          return;
        }
        message.success({ content: "Access token deleted successfully!", key });
        const newTokens = tokens.filter((a) => a.id !== id);
        setTokens(newTokens);
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Unexpected error happened", key });
      });
  };

  const cols = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Token",
      dataIndex: "accessToken",
      key: "accessToken",
      render: (token) => (
        <Popover
          content={<Paragraph copyable>{token}</Paragraph>}
          title={"Token"}
          style={{ maxWidth: "300px" }}
        >
          <Button type={"text"}>View</Button>
        </Popover>
      ),
    },
    {
      title: "Status",
      dataIndex: "isSuspended",
      key: "isSuspended",
      render: (val) => (
        <>
          {val && (
            <Tag color={"red"} key={val}>
              Not valid
            </Tag>
          )}
          {!val && (
            <Tag color={"success"} key={val}>
              Valid
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: renderActions,
    },
  ];

  return (
    <ViewData
      columns={cols}
      data={tokens}
      loadingState={loadingState}
      totalNumber={totalItems}
      onChange={onChange}
    />
  );
}
