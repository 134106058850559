import { ViewData } from "../../shared/ViewData";
import { useEffect, useState, useRef } from "react";
import { Helpers } from "../../shared/Helper";
import {
  Button,
  Col,
  message,
  Popconfirm,
  Popover,
  Row,
  Tag,
  Tooltip,
  Space,
  Input,
} from "antd";
import Repository from "../../helpers/Repository";
import Text from "antd/es/typography/Text";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

export const LinksSyncTable = ({
  data,
  loadingState,
  onChange,
  totalItems,
}) => {
  const [items, setItems] = useState([]);
  const searchInput = useRef(null);
  const [itemsCount, setItemsCount] = useState(0);
  const renderActions = (val, row) => (
    <Row gutter={[24, 24]}>
      {/* Delete Token*/}
      <Col span={6}>
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => deleteObject(row.id)}
        >
          <Tooltip title={"Delete Link"}>
            <Text
              type={"danger"}
              style={{ cursor: "pointer", fontSize: "18px" }}
            >
              <DeleteOutlined />
            </Text>
          </Tooltip>
        </Popconfirm>
      </Col>
    </Row>
  );

  const deleteObject = (id) => {
    const key = Helpers.makeKey(16);
    message
      .loading({ content: "Deleting Link, please wait...", duration: 0, key })
      .then();
    Repository.deleteSyncLink(id)
      .then((res) => {
        message.success({ content: "Link deleted successfully!", key }).then();
        const newItems = items.filter((a) => a.id !== id);
        setItems(newItems);
        setItemsCount(itemsCount - 1);
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Unexpected error happened", key }).then();
      });
  };

  const cols = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (link) => (
        <>
          <Popover
            title={"Facebook Link"}
            content={
              <>
                <div>Item ID: {link}</div>
                <a rel={"noreferrer"} href={link} target={"_blank"}>
                  Open Link in Facebook
                </a>
              </>
            }
          >
            <Button type={"text"}>View</Button>
          </Popover>
        </>
      ),
    },
    {
      title: "Max Count",
      dataIndex: "maxCount",
      key: "maxCount",
    },
    {
      title: "Current Count",
      dataIndex: "objectiveCount",
      key: "objectiveCount",
    },
    {
      title: "Operation",
      dataIndex: "objective",
      key: "objective",
    },
    {
      title: "Status",
      dataIndex: "isDone",
      key: "isDone",
      render: (val) => (
        <>
          {val && <Tag color={"success"}>Done</Tag>}
          {!val && <Tag color={"warning"}>Processing</Tag>}
        </>
      ),
    },
    {
      title: "Last Sync",
      dataIndex: "lastSent",
      key: "lastSent",
      render: (val) => (
        <>
          {val && (
            <Popover content={new Date(val).toLocaleString()} title="Last Sync">
              <Button type="text">View</Button>
            </Popover>
          )}
          {!val && "-"}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: renderActions,
    },
  ];

  useEffect(() => {
    setItems(data);
    setItemsCount(totalItems);
  }, [data, totalItems]);

  return (
    <ViewData
      columns={cols}
      data={items}
      loadingState={loadingState}
      totalNumber={itemsCount}
      onChange={onChange}
    />
  );
};
