import {DashboardCards} from "../dashboard/DashboardCards";
import {useEffect, useState} from "react";
import Repository from "../../helpers/Repository";
import {Button, Col, message, Modal, notification, Popconfirm, Popover, Row, Space} from "antd";
import {TokenItems} from "../tables/TokenItems";
import {Link, useRouteMatch} from "react-router-dom";
import {UploadTokens} from "../../shared/UploadTokens";
import {Helpers} from "../../shared/Helper";
import {useAuth} from "../../auth/UseAuth";
import {CloudDownloadOutlined} from "@ant-design/icons";
import ExportTokensModal from "./ExportTokensModal";


export function ManageTokens() {
    const {user} = useAuth();
    const [isCardsLoading, setIsCardsLoading] = useState(false);
    const [isTokensLoading, setIsTokensLoading] = useState(false);
    const [totalTokens, setTotalTokens] = useState(0);
    const [totalWorkingCount, setTotalWorkingCount] = useState(0);
    const [totalSuspendedCount, setTotalSuspendedCount] = useState(0);
    const [accessTokens, setAccessTokens] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [isTokenChecking, setIsTokenChecking] = useState(false);
    const [totalFollowTokensCount, setTotalFollowTokensCount] = useState(0);
    const [totalReactionTokensCount, setTotalReactionTokensCount] = useState(0);
    const [totalMethod3TokenCount, setTotalMethod3TokenCount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);


    Helpers.setTitle("Manage Access Tokens");

    useEffect(() => {
        loadTokensStatistics();
        loadTokens(pageIndex, pageSize);
    }, []);

    const loadTokensStatistics = () => {
        setIsCardsLoading(true);
        Repository.getStatistics().then(res => {
            setStatistics(res.data);

        }).catch(err => {
            console.log(err);
        })
            .finally(() => {
                setIsCardsLoading(false);
            })
    }

    Helpers.handleNotification("statisticsNotification", res => {
        console.log(res);
        setStatistics(res);
    });
    Helpers.handleNotification("tokenCheckCompleted", res => {
        setIsTokenChecking(false);
        notification.info({
            key: Helpers.makeKey(10),
            message: "Token check has completed",
            description: "Token Check process has completed, you can check statistics now.",
            placement: "bottomRight",
            duration: 5
        });
    });
    /*


    isCheckingTokens: false
    method3Tokens: 0
    processingItems: 0
    successItems: 1
    totalFollowTokens: 2
    totalObjects: 1
    totalReactionTokens: 4
    totalSuspendedTokens: 0
    totalTokens: 6
    totalWorkingToken: 6
     */
    const setStatistics = (obj) => {
        setTotalTokens(obj.totalTokens);
        setTotalWorkingCount(obj.totalWorkingToken);
        setTotalSuspendedCount(obj.totalSuspendedTokens);
        setIsTokenChecking(obj.isCheckingTokens);
        setTotalMethod3TokenCount(obj.method3Tokens);
        setTotalReactionTokensCount(obj.totalReactionTokens);
        setTotalFollowTokensCount(obj.totalFollowTokens);
    }
    const checkTokens = () => {
        setIsTokenChecking(true);
        Repository.checkAllTokens().then(res => {
            message.info({content: "Checking tokens is in progress, we will inform you when it finished"}).then();
        })
            .catch(err => {
                console.log(err);
                setIsTokenChecking(false);
            });
    }

    const loadTokens = (pageIndex, pageSize) => {
        setPageSize(pageSize);
        setPageIndex(pageIndex);
        setIsTokensLoading(true);
        Repository.getAccessTokens(pageSize, pageIndex, "desc").then(res => {
            setAccessTokens(res.data.data);
            setTotalTokens(res.data.totalCount);
            setIsTokensLoading(false);
        }).catch(err => {
            console.log(err);
            setIsTokensLoading(false);
        })
    }

    const deleteExpiredTokens = () => {
        const key = Helpers.makeKey(15);
        message.loading({content: "Deleting expired token...", key, duration: 0}).then();
        Repository.deleteExpiredTokens().then(res => {
            const count = res.data;
            if (count === 0) {
                message.info({key, content: `No Expired tokens to be deleted`}).then();
            } else
                message.success({key, content: `${res.data} tokens has been deleted successfully!`}).then();
        })
            .catch(err => {
                console.log(err);
                message.error({key, content: "Unexpected error occurred."}).then();
            })
    }
    const cardData = [
        {
            title: "Total Access Tokens",
            value: totalTokens,
            type: "primary"
        },
        {
            title: "Working Tokens",
            value: totalWorkingCount,
            type: "success"
        },

        {
            title: "Follow Tokens Count",
            value: totalFollowTokensCount,
            type: "magenta"
        },
        {
            title: "Reaction Tokens Count",
            value: totalReactionTokensCount,
            type: "warning"
        },
        {
            title: "Method 3 Tokens Count",
            value: totalMethod3TokenCount,
            type: "primary"
        },
        {
            title: "Suspended tokens",
            value: totalSuspendedCount,
            type: "danger"
        },
    ]

    const {url} = useRouteMatch();
    const handleCancel = () => {
        setModalVisible(false);
    }

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    {/*<h1>Manage Tokens</h1>*/}
                    <DashboardCards data={cardData} loadingState={isCardsLoading}/>
                </Col>
                <Col span={24}>
                    <Space style={{width: '100%'}}>
                        <Link to={`${url}/add`} className={"ant-btn ant-btn-primary"}>
                            Add New Token
                        </Link>
                        <UploadTokens refreshTokens={() => loadTokens(pageIndex, pageSize)}
                                      refreshStatistics={loadTokensStatistics}/>
                        <Button type={"primary"} loading={isTokenChecking} onClick={checkTokens}>
                            {isTokenChecking ? "Checking Tokens" : "Check Tokens"}
                        </Button>
                        <Popconfirm title="Are you sure?" onConfirm={deleteExpiredTokens}>
                            <Button type={"dashed"}>
                                Delete Expired token
                            </Button>
                        </Popconfirm>
                        <Button type={"ghost"} icon={<CloudDownloadOutlined/>} onClick={() => setModalVisible(true)}>
                            Download Tokens
                        </Button>
                    </Space>

                </Col>
                <Col span={24}>
                    <TokenItems accessTokens={accessTokens} loadingState={isTokensLoading} totalItems={totalTokens}
                                onChange={loadTokens}/>
                </Col>

            </Row>
           <ExportTokensModal modalVisible={modalVisible} handleCancel={handleCancel} />
        </>


    )
}