import {Button, Checkbox, Form, InputNumber, message, Modal, Select, Skeleton} from "antd";
import {useEffect, useState} from "react";
import Repository from "../../helpers/Repository";
import {Option} from "antd/es/mentions";
import {logDOM} from "@testing-library/react";

export default function ExportTokensModal({
                                              modalVisible,
                                              handleCancel
                                          }) {

    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(Number.MAX_SAFE_INTEGER);
    const [count, setCount] = useState(0);
    const [isLoading, setIdLoading] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        if (modalVisible) {
            console.log("Modal Begun");
            getNumbers();
        }
    }, [modalVisible])

    const getNumbers = () => {
        setIdLoading(true);
        Repository.getExportNumbers().then(res => {
            setFrom(res.data.minId);
            setTo(res.data.maxId);
            setCount(res.data.count);
        })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setIdLoading(false));
    }

    const onFinish = (values) => {
        const errs = form.getFieldsError().filter(a => a.errors.length > 0);
        if (errs.length) {
            message.error("Data is not valid, please fix it then try again.").then();
            return;
        }

        const body = form.getFieldsValue();
        const now = new Date();
        const fileName = `Tokens-${body.type}-${now.getDay()}${now.getMilliseconds()}`;
        Repository.exportTokens(body).then(res => {
            console.log(res)
            downloadToken(res.data, fileName);
        })
            .catch(err => console.log(err));
    };

    const downloadToken = (tokens, fileName) => {
        const element = document.createElement("a");
        const file = new Blob([tokens], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const footer = [
        <Button key={"cancel"} onClick={handleCancel}>Cancel</Button>,
        <Button type={"primary"} key={"ok"} onClick={onFinish}>Ok</Button>
    ]
    return (
        <Modal title="Export Tokens"
               visible={modalVisible}
               onCancel={handleCancel}
               footer={footer}
        >
            <Skeleton active loading={isLoading} paragraph={{
                rows: 5
            }}
                      title={false}
            >
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Form.Item label={"Token Type to download"} name={"type"}
                               rules={[{
                                   required: true,
                                   message: "Token type is required."
                               }]}
                               initialValue={"All"}
                    >
                        <Select placeholder="Select Token to download">
                            <Option value="All">All</Option>
                            <Option value="Reaction">Reaction</Option>
                            <Option value="Follow">Follow</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={"includeSuperToken"} valuePropName="checked"
                               rules={[
                                   {type: "boolean", defaultField: false}
                               ]}
                    >
                        <Checkbox defaultChecked={false} name={"includeSuperToken"}>Include Super Tokens</Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Token Start"
                        name="start"
                        rules={[
                            {
                                required: true,
                                message: "From Value is required"
                            },
                            {
                                type: "number",
                                min: from,
                                max: to,
                                defaultField: from
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber placeholder="From" style={{width: "100%"}} defaultValue={from}/>
                    </Form.Item>
                    <Form.Item
                        label="Token End"
                        name="end"
                        rules={[
                            {
                                required: true,
                                message: "To Value is required"
                            },
                            {
                                type: "number",
                                min: from,
                                max: to,
                                defaultField: to
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber placeholder="To" style={{width: "100%"}} defaultValue={to}/>
                    </Form.Item>
                    <h5>{"Total Tokens Count " + count}</h5>
                </Form>
            </Skeleton>
        </Modal>
    )
}