import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Repository from "../../helpers/Repository";
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Select, Space} from "antd";
import {Option} from "antd/es/mentions";
import moment from "moment";
import Text from "antd/es/typography/Text";
import {Helpers} from "../../shared/Helper";

export function AddObject() {
    Helpers.setTitle("New Object");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [object, setObject] = useState(undefined);
    const {id} = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    useEffect(() => {
        const idResult = parseInt(id);
        if (!isNaN(idResult)) {
            console.log(idResult);
            getObject(idResult);
        }

        setIsSubmitDisabled(form.getFieldsError().filter(({errors}) => errors.length).length > 0);
    }, [id, form]);

    const getObject = id => {
        Repository.getLinkById(id).then(res => {
            console.log(res);
            document.title = "Edit Object";
            setObject(res.data);
            form.setFieldsValue({
                name: res.data.name,
                targetObjectId: res.data.targetObjectId,
                maxCount: res.data.maxCount,
                operationType: res.data.operationType,
                reactionType: res.data.reactionType,
                scheduleDate: res.data.scheduleDate ? moment(res.data.scheduleDate) : undefined
            });
            setIsSubmitDisabled(false);
            if (res.data.status.toLowerCase() !== "waiting") {
                setIsSubmitDisabled(true);
            }
        });
    }


    const onChange = (_, __) => {
        const now = moment().add(1, "minutes");
        const newDate = form.getFieldsValue().scheduleDate;
        console.log(now >= newDate);
        console.log(newDate);
        if (newDate && now >= newDate) {
            form.setFields([
                {
                    name: "scheduleDate",
                    errors: ["Schedule Date must be in the future, at least one minute later"]
                }
            ])
        } else {
            form.setFields([
                {
                    name: "scheduleDate",
                    errors: []
                }
            ]);

        }
        setIsSubmitDisabled(form.getFieldsError().filter(({errors}) => errors.length).length > 0);
    }

    const submit = () => {
        if (isSubmitDisabled) {
            return;
        }
        const formValues = form.getFieldsValue();
        const body = {...formValues};
        if(body.scheduleDate )
            body.scheduleDate = body.scheduleDate.format();

        console.log(body);
        setIsSubmitting(true);
        const key = Helpers.makeKey(18);
        message.loading({
            content: object ? "Updating, please wait..." : "Adding new object, please wait...",
            key,
            duration: 0
        }).then();
        if (object) {
            Repository.updateLink(id, body).then(res => {
                setObject(res.data);
                message.success({content: "Operation done successfully!", key}).then();
                history.push("/objects")
            }).catch(err => {
                console.log(err);
                message.error({content: "Unexpected error occurred!", key}).then();
            })
                .finally(() => {
                    setIsSubmitting(false);
                })
        } else {
            Repository.addLink(body).then(res => {
                setObject(res.data);
                message.success({content: "Operation done successfully!", key}).then();

                history.push("/objects")
            }).catch(err => {
                console.log(err);
                message.error({content: "Unexpected error occurred!", key}).then();
            }).finally(() => {
                setIsSubmitting(false);
            })
        }

    }

    return (
        <Row gutter={[24, 24]}>
            <Col span={12}>
                <Form
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    onFieldsChange={onChange}
                >
                    <Form.Item label="Name" name="name">
                        <Input placeholder="Link name"/>
                    </Form.Item>
                    <Form.Item
                        label="Object ID"
                        name="targetObjectId"
                        rules={[
                            {
                                required: true,
                                message: "Object ID is required"
                            }
                        ]}
                        hasFeedback
                    >
                        <Input placeholder="Object ID"/>
                    </Form.Item>
                    <Form.Item
                        label="Max Count"
                        name="maxCount"
                        rules={[
                            {
                                required: true,
                                message: "Max Count is required"
                            },
                            {
                                type: "number",
                            }

                        ]}
                        hasFeedback
                    >
                        <InputNumber min={1} max={2147483647} placeholder="Max Count" style={{width: "100%"}}/>
                    </Form.Item>
                    <Form.Item
                        label="Operation"
                        name="operationType"
                        rules={[
                            {
                                required: true,
                                message: "Operation is required"
                            }
                        ]}
                        hasFeedback
                    >
                        <Select placeholder={"Select Operation Type"} defaultActiveFirstOption defaultValue={"LikePage"}>
                            <Option value={"LikePage"}>Like Page</Option>
                            <Option value={"FollowPage"}>Follow Page</Option>
                            <Option value={"FollowAccount"}>Follow Account</Option>
                            <Option value={"Reaction"}>Reaction</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Reaction"
                        name="reactionType"
                        hasFeedback
                    >
                        <Select placeholder={"Select Operation Type"} defaultActiveFirstOption defaultValue={"None"}>
                            <Option value={"None"}>None</Option>
                            <Option value={"Like"}>Like</Option>
                            <Option value={"Love"}>Love</Option>
                            <Option value={"Haha"}>Haha</Option>
                            <Option value={"Wow"}>Wow</Option>
                            <Option value={"Sad"}>Sad</Option>
                            <Option value={"Angry"}>Angry</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Start At"
                        name="scheduleDate"
                    >
                        <DatePicker
                            showTime format="YYYY-MM-DD HH:mm:ss" style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Space>
                            <Button type="primary" onClick={submit}
                                    loading={isSubmitting}
                                    disabled={isSubmitDisabled}
                            >
                                Save
                            </Button>
                            {object && object.status.toLowerCase() !== "waiting" &&
                            <Text type={"warning"}>Object must be in <Text code>Waiting</Text> state.</Text>}
                        </Space>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}