import { Button, Col, Row, Space } from "antd";
import { DashboardCards } from "../dashboard/DashboardCards";
import { useEffect, useState } from "react";
import Repository from "../../helpers/Repository";
import { LinksSyncTable } from "../tables/LinksSyncTable";
import { AddSyncLinkModal } from "./AddSyncLinkModal";
import { LinkSyncSearch } from "./LinkSyncSearch";

export const LinksSync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const cards = [
    {
      title: "Total Links",
      value: totalCount,
      type: "primary",
    },
  ];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = (q = "") => {
    listLinks(pageIndex, pageSize, q);
  };

  const listLinks = (pageIndex, pageSize, q = "") => {
    setIsLoading(true);
    setPageIndex(pageIndex);
    setPageSize(pageSize);
    Repository.getSyncLinks(q, pageIndex, pageSize)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <DashboardCards data={cards} loadingState={isLoading} />
      </Col>
      <Col span={24}>
        <Space>
          <Button type="primary" onClick={() => setIsModelVisible(true)}>
            Add Link
          </Button>
          <AddSyncLinkModal
            visible={isModelVisible}
            setVisible={setIsModelVisible}
            onAdd={refresh}
          />
          <LinkSyncSearch onSearch={refresh} />
        </Space>
      </Col>
      <Col span={24}>
        <LinksSyncTable
          loadingState={isLoading}
          totalItems={totalCount}
          data={data}
          onChange={listLinks}
        />
      </Col>
    </Row>
  );
};
