import {Spin, Table} from "antd";
import {useEffect, useState} from "react";
import {LoadingOutlined} from "@ant-design/icons";

export function ViewData({data, columns, loadingState, totalNumber, onChange}) {
    const [dataSource, setDataSource] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tablePageSize, setTablePageSize] = useState(5);
    const [tableTotalNumber, setTotalNumber] = useState(10);

    useEffect(() => {
        setDataSource(data);
        setTableColumns(columns);
        setIsLoading(loadingState);
        setTotalNumber(totalNumber);
    }, [data, columns, loadingState, totalNumber]);


    const onPageChanged = (page, pageSize) => {
        console.log(page, pageSize);
        if (pageSize <= tableTotalNumber) {
            setTablePageSize(pageSize);
            onChange(page, pageSize);
        }
    }


    return (
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
            <Table
                dataSource={dataSource}
                columns={tableColumns}
                pagination={
                    {
                        showSizeChanger: true,
                        pageSize: tablePageSize,
                        total: tableTotalNumber,
                        pageSizeOptions: [1, 5, 10, 20, 50, 100],
                        onChange: onPageChanged,
                        showTotal: t => `Total ${t} items`
                    }
                }
            />
        </Spin>

    )
}