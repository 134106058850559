import { LockOutlined } from "@ant-design/icons";
import { Button, Input, Form, message } from "antd";
import { useEffect } from "react";

export const OperactionPassword = ({ onValidate }) => {
  useEffect(() => {}, []);
  const password = "Like$$$$$Story@@@@@";
  const onFinish = (v) => {
    if (v.password === password) {
      onValidate();
    } else {
      message.error("Wrong Password!");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        name="normal_login"
        className="login-form"
        style={{ marginTop: "50px", width: "300px" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
