export class AuthService {
    static isAuthenticated() {
        return localStorage.getItem("userToken") && localStorage.getItem("fullName")
    }

    static login(obj) {
        if (!obj)
            return;

        localStorage.setItem("userToken", obj.token);
        localStorage.setItem("fullName", obj.fullName);
    }

    static getName() {
        return localStorage.getItem("fullName")
    }

    static getToken(){
        return localStorage.getItem("userToken")
    }

    static logout() {
        localStorage.clear();
    }
}