import {
  Popover,
  Button,
  Tag,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import { ViewData } from "./../../shared/ViewData";
import { DeleteOutlined } from "@ant-design/icons";
import { Helpers } from "./../../shared/Helper";
import Repository from "./../../helpers/Repository";
import { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
export const OperationSchedulerTable = ({
  data,
  loadingState,
  totalItems,
  onChange,
}) => {
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const renderActions = (val, row) => (
    <Row gutter={[24, 24]}>
      {/* Delete Token*/}
      <Col span={6}>
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => deleteObject(row.id)}
        >
          <Tooltip title={"Delete Link"}>
            <Text
              type={"danger"}
              style={{ cursor: "pointer", fontSize: "18px" }}
            >
              <DeleteOutlined />
            </Text>
          </Tooltip>
        </Popconfirm>
      </Col>
    </Row>
  );
  useEffect(() => {
    setItems(data);
    setItemsCount(totalItems);
  }, [data, totalItems]);
  const deleteObject = (id) => {
    const key = Helpers.makeKey(16);
    message
      .loading({ content: "Deleting Link, please wait...", duration: 0, key })
      .then();
    Repository.deleteOperationItem(id)
      .then((res) => {
        message.success({ content: "Link deleted successfully!", key }).then();
        const newItems = items.filter((a) => a.id !== id);
        setItems(newItems);
        setItemsCount(itemsCount - 1);
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Unexpected error happened", key }).then();
      });
  };

  const cols = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Facebook Id",
      dataIndex: "targetId",
      key: "TargetId",
      render: (id) => (
        <>
          <Popover
            title={"Facebook Link"}
            content={
              <>
                <div>Item ID: {id}</div>
                <a
                  rel={"noreferrer"}
                  href={`https://www.facebook.com/${id}`}
                  target={"_blank"}
                >
                  Open Link in Facebook
                </a>
              </>
            }
          >
            <Button type={"text"}>View</Button>
          </Popover>
        </>
      ),
    },
    {
      title: "Max Count",
      dataIndex: "count",
      key: "Count",
    },
    {
      title: "Success",
      dataIndex: "success",
      key: "success",
    },
    {
      title: "Failed",
      dataIndex: "failed",
      key: "failed",
    },
    {
      title: "Status",
      dataIndex: "isProcessing",
      key: "isProcessing",
      render: (isProcessing) => (
        <>
          {isProcessing && (
            <Tag color={"warning"} key={isProcessing}>
              Processing
            </Tag>
          )}
          {!isProcessing && (
            <Tag color={"default"} key={!isProcessing}>
              -
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
    },
    {
      title: "Start Date",
      dataIndex: "startAt",
      key: "startAt",
      render: (val) => (
        <>
          {val && (
            <Popover
              content={new Date(val).toLocaleString()}
              title="Start Date"
            >
              <Button type="text">View</Button>
            </Popover>
          )}
          {!val && "-"}
        </>
      ),
    },
    {
      title: "Finish Date",
      dataIndex: "endAt",
      key: "endAt",
      render: (val) => (
        <>
          {val && (
            <Popover
              content={new Date(val).toLocaleString()}
              title="Finish Date"
            >
              <Button type="text">View</Button>
            </Popover>
          )}
          {!val && "-"}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: renderActions,
    },
  ];

  return (
    <ViewData
      columns={cols}
      data={items}
      loadingState={loadingState}
      totalNumber={itemsCount}
      onChange={onChange}
    />
  );
};
