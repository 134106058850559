import { createContext, useContext, useState } from "react";

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [passPassword, setPassPassword] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const login = (obj) => {
    if (!obj) return;

    localStorage.setItem("token", obj.token);
    localStorage.setItem("fullName", obj.fullName);
    setUser(obj);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setIsAuthenticated(false);
  };

  // Return the user object and auth methods
  return {
    user,
    isAuthenticated,
    login,
    logout,
    passPassword,
    setPassPassword,
  };
}
