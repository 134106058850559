import {Button, Col, Form, Input, message, Row} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {Helpers} from "../shared/Helper";
import Repository from "../helpers/Repository";
import {AuthService} from "../shared/AuthService";
import {useHistory} from "react-router-dom";
import {useAuth} from "../auth/UseAuth";

export function Login() {
    Helpers.setTitle("Login");
    const history = useHistory();
    const {login} = useAuth();
    // if (AuthService.isAuthenticated()) {
    //     history.push("/dashboard");
    // }
    const onFinish = (values) => {
        const key = Helpers.makeKey(19);
        message.loading({content: "Logging in, please wait!", key, duration: 0});
        Repository.login(values).then(res => {
            AuthService.login(res.data);
            login(res.data);
            message.success({content: "Logged in successfully!, redirecting...", key, duration: 1}).then(() => {
                history.push("/dashboard");
            });
        })
            .catch(err => {
                console.log(err);
                message.error({content: "Email or password is incorrect", key});
            })
    };
    return (
        <>
            <Row gutter={[24, 24]}>
                <Col span={9}/>
                <Col span={6}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        style={{marginTop: "50px"}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: "email",
                                    message: "Please provide valid email address"
                                }
                            ]}
                        >
                            <Input prefix={<MailOutlined className="site-form-item-icon"/>}
                                   placeholder="Email Address"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                            </Button>

                        </Form.Item>
                    </Form>

                </Col>
                <Col span={9}/>
            </Row>
        </>
    )
}