import { useEffect, useState } from "react";
//import { OperactionPassword } from "./OperationPassword";
import { Row, Button, Popconfirm, message } from "antd";
import { Col } from "antd";
import { Space } from "antd";
import { OperationSchedulerTable } from "./../tables/OperationSchedulerTable";
import Repository from "./../../helpers/Repository";
import { DashboardCards } from "./../dashboard/DashboardCards";
import { AddOperationModal } from "./AddOperationModal";
import { UploadTokens } from "./UploadTokens";
import { OperactionPassword } from "./OperationPassword";
import { useAuth } from "../../auth/UseAuth";
import { TokenItems } from "./../tables/TokenItems";
import { Helpers } from "./../../shared/Helper";
import { TokenOperationItems } from "./../tables/TokenOperationItems";

export const OperactionScheduler = () => {
  const { passPassword, setPassPassword } = useAuth();
  const [isTokensLoading, setIsTokensLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tokenCount, setTokenCount] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [accessTokens, setAccessTokens] = useState([]);
  const [isTokenChecking, setIsTokenChecking] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);

  Helpers.setTitle("Operation Scheduler");
  useEffect(() => {
    getOperations(1, 10);
    loadTokens(1, 10);
    loadTokensStatistics();
  }, []);
  const setStatistics = (obj) => {
    setIsTokenChecking(obj.isCheckingOperationTokens);
  };
  const loadTokensStatistics = () => {
    Repository.getStatistics()
      .then((res) => {
        setStatistics(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOperations = (pageIndex, pageSize) => {
    setIsDataLoading(true);
    Repository.getOperationScheduler(pageSize, pageIndex)
      .then((res) => {
        setData(res.data.data);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsDataLoading(false));
  };
  const loadTokens = (pageIndex, pageSize) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setIsTokensLoading(true);
    Repository.getOperationTokens(pageSize, pageIndex, "desc")
      .then((res) => {
        setAccessTokens(res.data.data);
        setTokenCount(res.data.totalCount);
        setIsTokensLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsTokensLoading(false);
      });
  };

  const deleteExpiredTokens = () => {
    const key = Helpers.makeKey(15);
    message
      .loading({ content: "Deleting expired token...", key, duration: 0 })
      .then();
    Repository.deleteExpiredOperationsTokens()
      .then((res) => {
        const count = res.data;
        if (count === 0) {
          message
            .info({ key, content: `No Expired tokens to be deleted` })
            .then();
        } else
          message
            .success({
              key,
              content: `${res.data} tokens has been deleted successfully!`,
            })
            .then();
      })
      .catch((err) => {
        console.log(err);
        message.error({ key, content: "Unexpected error occurred." }).then();
      });
  };
  const deleteAllTokens = () => {
    const key = Helpers.makeKey(15);
    message
      .loading({ content: "Deleting all token...", key, duration: 0 })
      .then();
    Repository.resetOperationToken()
      .then((res) => {
        const count = res.data;
        if (count === 0) {
          message
            .info({ key, content: `No Expired tokens to be deleted` })
            .then();
        } else
          message
            .success({
              key,
              content: `${res.data} tokens has been deleted successfully!!`,
            })
            .then();
      })
      .catch((err) => {
        console.log(err);
        message.error({ key, content: "Unexpected error occurred." }).then();
      });
  };

  const checkTokens = () => {
    setIsTokenChecking(true);
    Repository.checkAllOperationsTokens()
      .then((res) => {
        message
          .info({
            content:
              "Checking tokens is in progress, we will inform you when it finished",
          })
          .then();
      })
      .catch((err) => {
        console.log(err);
        setIsTokenChecking(false);
      });
  };

  const refresh = () => {
    getOperations(1, 10);
  };
  const cardData = [
    {
      title: "Total Count",
      value: totalCount,
      type: "primary",
    },
    {
      title: "Total Token Count",
      value: tokenCount,
      type: "primary",
    },
  ];

  return (
    <>
      {!passPassword && (
        <OperactionPassword onValidate={() => setPassPassword(true)} />
      )}
      {passPassword && (
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <DashboardCards data={cardData} />
            </Col>
            <Col span={24}>
              <Space style={{ width: "100%" }}>
                <Button type="primary" onClick={() => setIsModelVisible(true)}>
                  Add Link
                </Button>
                <UploadTokens
                  refreshStatistics={() => loadTokens(pageIndex, pageSize)}
                />
                <Button
                  type={"primary"}
                  loading={isTokenChecking}
                  onClick={checkTokens}
                >
                  {isTokenChecking ? "Checking Tokens" : "Check Tokens"}
                </Button>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={deleteExpiredTokens}
                >
                  <Button type={"dashed"}>Delete Expired token</Button>
                </Popconfirm>
                <Popconfirm title="Are you sure?" onConfirm={deleteAllTokens}>
                  <Button
                    type={"ghost"}
                    color={"red"}
                    style={{ color: "#ff4d4f", borderColor: "#ff4d4f" }}
                  >
                    DELETE ALL Tokens
                  </Button>
                </Popconfirm>
                <AddOperationModal
                  visible={isModelVisible}
                  setVisible={setIsModelVisible}
                  onAdd={refresh}
                />
              </Space>
            </Col>
            <Col span={24}>
              <OperationSchedulerTable
                data={data}
                loadingState={isDataLoading}
                totalItems={totalCount}
                onChange={getOperations}
              />
            </Col>
            <AddOperationModal />
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space style={{ width: "100%" }}></Space>
            </Col>
            <Col span={24}>
              <TokenOperationItems
                accessTokens={accessTokens}
                loadingState={isTokensLoading}
                totalItems={tokenCount}
                onChange={loadTokens}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
