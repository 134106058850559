import {ViewData} from "../../shared/ViewData";
import {Button, Col, message, Popconfirm, Popover, Row, Tag, Tooltip} from "antd";
import {Link} from "react-router-dom";
import Text from "antd/es/typography/Text";
import {
    DeleteOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    RedoOutlined,
    RetweetOutlined
} from "@ant-design/icons";
import {Helpers} from "../../shared/Helper";
import Repository from "../../helpers/Repository";
import {useEffect, useState} from "react";

export function LinksItems({data, loadingState, onChange, totalItems}) {
    const [items, setItems] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);
    const renderActions = (val, row) =>
        <Row gutter={[24, 24]}>
            {/* Delete Token*/}
            <Col span={6}>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteObject(row.id)}
                >
                    <Tooltip title={"Delete Object"}>
                        <Text type={"danger"} style={{cursor: "pointer", fontSize: "18px"}}><DeleteOutlined/></Text>
                    </Tooltip>
                </Popconfirm>
            </Col>
            {/* Start */}
            {row.status.toLowerCase() === "waiting" &&
            <Col span={6}>
                <Tooltip title={"Start"}>
                    <Text type={"success"} style={{cursor: "pointer", fontSize: "18px"}}
                          onClick={() => start(row.id)}
                    ><PlayCircleOutlined/></Text>
                </Tooltip>
            </Col>
            }
            {/* Cancel */}
            {row.status.toLowerCase() === "processing" &&
            <Col span={6}>
                <Tooltip title={"Cancel"}>
                    <Text type={"warning"} style={{cursor: "pointer", fontSize: "18px"}}
                          onClick={() => cancel(row.id)}
                    ><PauseCircleOutlined/></Text>
                </Tooltip>
            </Col>
            }
            {/* Restart */}
            {row.status.toLowerCase() === "finished" &&
            <Col span={6}>
                <Tooltip title={"Restart"}>
                    <Text type={"secondary"} style={{cursor: "pointer", fontSize: "18px"}}
                          onClick={() => start(row.id)}
                    ><RedoOutlined/></Text>
                </Tooltip>
            </Col>
            }
            {/* Restart */}
            {row.status.toLowerCase() === "cancelled" &&
            <Col span={6}>
                <Tooltip title={"Restart"}>
                    <Text type={"secondary"} style={{cursor: "pointer", fontSize: "18px"}}
                          onClick={() => start(row.id)}
                    ><RedoOutlined/></Text>
                </Tooltip>
            </Col>
            }
        </Row>;

    const deleteObject = id => {
        const key = Helpers.makeKey(16);
        message.loading({content: "Deleting object, please wait...", duration: 0, key});
        Repository.deleteLink(id)
            .then(res => {
                if (!res.data) {
                    message.error({content: "Unexpected error happened", key});
                    return;
                }
                message.success({content: "Object deleted successfully!", key});
                const newItems = items.filter(a => a.id !== id);
                setItems(newItems);
                setItemsCount(itemsCount - 1);
            })
            .catch(err => {
                console.log(err);
                message.error({content: "Unexpected error happened", key});
            })
    };
    const start = id => {
        const key = Helpers.makeKey(17);
        message.loading({content: "Starting task...", key, duration: 0}).then();
        Repository.start(id).then(res => {
            message.success({content: "Task started successfully!", key}).then();
            const index = items.findIndex(a => a.id === id);
            const newItems = [...items];
            newItems[index].status = "Processing";
            setItems(newItems);
        }).catch(err => {
            console.log(err);
            message.error({content: "Unexpected error happened", key}).then();
        });

    }
    const cancel = id => {
        const key = Helpers.makeKey(12);
        message.loading({content: "Cancelling task...", key, duration: 0}).then();
        Repository.cancel(id).then(res => {
            message.success({content: "Task cancelled successfully!", key}).then();
            const index = items.findIndex(a => a.id === id);
            const newItems = [...items];
            newItems[index].status = "Cancelled";
            setItems(newItems);
        }).catch(err => {
            console.log(err);
            message.error({content: "Unexpected error happened", key}).then();
        });
    }


    const cols = [
        {
            title: "#",
            dataIndex: 'id',
            key: 'id',
            render: id =>
                <>
                    <Link to={`/objects/${id}`}>{id}</Link>
                </>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Facebook Id',
            dataIndex: 'targetObjectId',
            key: 'targetObjectId',
            render: id =>
                <>
                    <Popover title={"Facebook Link"} content={
                        <>
                            <div>Item ID: {id}</div>
                            <a rel={"noreferrer"} href={`https://www.facebook.com/${id}`} target={"_blank"}>Open Link in
                                Facebook</a>
                        </>

                    }>
                        <Button type={"text"}>View</Button>
                    </Popover>
                </>
        },
        {
            title: 'Max Count',
            dataIndex: 'maxCount',
            key: 'maxCount'
        }, {
            title: 'Current Count',
            dataIndex: 'currentCount',
            key: 'currentCount'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <>
                    {status.toLowerCase() === "waiting" && <Tag color={"blue"} key={status}>
                        {status}
                    </Tag>}
                    {status.toLowerCase() === "processing" && <Tag color={"warning"} key={status}>
                        {status}
                    </Tag>}
                    {status.toLowerCase() === "finished" && <Tag color={"success"} key={status}>
                        {status}
                    </Tag>}
                    {status.toLowerCase() === "cancelled" && <Tag color={"red"} key={status}>
                        {status}
                    </Tag>}
                </>
            )

        }, {
            title: 'Operation',
            dataIndex: 'operationType',
            key: 'operationType'
        }, {
            title: 'Reaction',
            dataIndex: 'reactionType',
            key: 'reaction'
        }, {
            title: 'Success',
            dataIndex: 'success',
            key: 'success'
        }, {
            title: 'Failed',
            dataIndex: 'failed',
            key: 'failed'
        }, {
            title: 'Schedule Date',
            dataIndex: 'scheduleDate',
            key: 'runAt',
            render: val =>
                <>
                    {val && <Popover content={new Date(val).toLocaleString()} title="Schedule Date">
                        <Button type="text">View</Button>
                    </Popover>}
                    {!val && "-"}
                </>

        }, {
            title: 'Start Date',
            dataIndex: 'startedAt',
            key: 'startDate',
            render: val =>
                <>
                    {val && <Popover content={new Date(val).toLocaleString()} title="Start Date">
                        <Button type="text">View</Button>
                    </Popover>}
                    {!val && "-"}
                </>

        }, {
            title: 'Finish Date',
            dataIndex: 'finishedAt',
            key: 'finishDate',
            render: val =>
                <>
                    {val && <Popover content={new Date(val).toLocaleString()} title="Finish Date">
                        <Button type="text">View</Button>
                    </Popover>}
                    {!val && "-"}
                </>
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: renderActions,
        }
    ];

    useEffect(() => {
        setItems(data);
        setItemsCount(totalItems);
    }, [data, totalItems]);

    return (
        <ViewData columns={cols} data={items} loadingState={loadingState} totalNumber={itemsCount} onChange={onChange}/>
    )
}