import {Button, Dropdown, Menu, message} from "antd";
import {CloudUploadOutlined, DownOutlined} from "@ant-design/icons";
import {useRef, useState} from "react";
import Repository from "../helpers/Repository";
import {Helpers} from "./Helper";

export function UploadTokens({refreshTokens, refreshStatistics}) {
    const [tokenType, setTokenType] = useState("_");
    const inputFile = useRef(null)

    const prepareUpload = type => {
        setTokenType(type);
        inputFile.current.click();
    }
    const uploadTokens = (tokensList) => {
        console.log(tokensList);
        const key = Helpers.makeKey(12);
        message.loading({content: `Uploading ${tokensList.length} tokens...`, key, duration: 0}).then();
        const type = tokenType === "_" ? null : tokenType === 'true'
        const tokens = tokensList.map(itm => {
            return {
                token: itm,
                isReactionToken: type
            }
        });
        const tokensToUpload = tokens.filter(a=>a.token);
        Repository.AddAccessTokenList(tokensToUpload).then(res => {
            if (!res.data) {
                console.log(res.data);
                message.error({content: "Unexpected error happened", key});
                return;
            }
            message.success({content: "Uploading done!", key});
            console.log(res);
            refreshTokens();
            refreshStatistics();
        }).catch(err => {
            console.log(err);
            message.error({content: "Unexpected error happened", key});
        })
    }

    const onFileChanged = event => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const tokens = reader.result.split(/\r\n|\n|\r/);
                uploadTokens(tokens);
            }
            reader.readAsText(file);
        }
    }

    const uploadFilesMenu = (
        <Menu>
            <Menu.Item key="0" onClick={() => prepareUpload("_")}>
                <span>Method 3 - All</span>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="1" onClick={() => prepareUpload("true")}>
                <span>Method 2 - Reaction</span>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="2" onClick={() => prepareUpload("false")}>
                <span>Method 1 - Follow, Like Post</span>
            </Menu.Item>
        </Menu>
    );
    return (
        <>
            <Dropdown overlay={uploadFilesMenu} trigger={['click']}>
                <Button type={"primary"} icon={<CloudUploadOutlined/>}>
                    Upload Token <DownOutlined/>
                </Button>
            </Dropdown>
            <input type={"file"} name={"uploader"} ref={inputFile} multiple={false} style={{display: "none"}}
                   onChange={onFileChanged}/>
        </>
    )
}