import axios from "axios";
import GlobalValues from "../Constants/GlobalValues";

axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    document.location.href = "/login";
  }
  return Promise.reject(error);
});
export default class Repository {
  // Objects
  static getAllLinks(pageSize = 10, pageIndex = 1, orderBy = "asc") {
    return axios.get(
      GlobalValues.endPointUrl +
        `/objects?orderBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}`
    );
  }

  static getLinkById(id) {
    return axios.get(GlobalValues.endPointUrl + `/objects/${id}`);
  }

  static updateLink(id, body) {
    return axios.post(GlobalValues.endPointUrl + `/objects/${id}`, body);
  }

  static addLink(body) {
    return axios.post(GlobalValues.endPointUrl + `/objects`, body);
  }

  static deleteLink(linkId) {
    return axios.delete(GlobalValues.endPointUrl + `/objects/${linkId}`);
  }

  static start(id) {
    return axios.post(GlobalValues.endPointUrl + `/objects/start/${id}`);
  }

  static cancel(id) {
    return axios.post(GlobalValues.endPointUrl + `/objects/cancel/${id}`);
  }

  static getStatistics() {
    return axios.get(GlobalValues.endPointUrl + `/objects/statistics`);
  }

  // Tokens

  static getAccessTokens(pageSize = 10, pageIndex = 1, orderBy = "asc") {
    return axios.get(
      GlobalValues.endPointUrl +
        `/accessTokens?orderBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}`
    );
  }

  static AddAccessToken(accessTokenObject) {
    return axios.post(
      GlobalValues.endPointUrl + `/accessTokens`,
      accessTokenObject
    );
  }

  static AddAccessTokenList(accessTokensList) {
    return axios.post(
      GlobalValues.endPointUrl + `/accessTokens/list`,
      accessTokensList
    );
  }

  static deleteAccessToken(tokenId) {
    return axios.delete(GlobalValues.endPointUrl + `/accessTokens/${tokenId}`);
  }

  static deleteExpiredTokens() {
    return axios.delete(
      GlobalValues.endPointUrl + `/accessTokens/deleteExpiredTokens`
    );
  }

  static checkAccessToken(tokenId) {
    return axios.post(
      GlobalValues.endPointUrl + `/accessTokens/check/${tokenId}`
    );
  }

  static checkAllTokens() {
    return axios.post(GlobalValues.endPointUrl + `/accessTokens/check-all`);
  }

  static getExportNumbers() {
    return axios.get(GlobalValues.endPointUrl + `/accessTokens/numbers`);
  }

  static exportTokens(data) {
    return axios.post(GlobalValues.endPointUrl + `/accessTokens/export`, data);
  }

  // Auth
  static login(obj) {
    return axios.post(GlobalValues.endPointUrl + `/auth/login`, obj);
  }

  // Logs
  static getAllEventLogs(pageSize = 10, pageIndex = 1) {
    return axios.get(
      GlobalValues.endPointUrl +
        `/eventLogs?pageSize=${pageSize}&pageIndex=${pageIndex}`
    );
  }

  // Purchase Request
  static getAllPurchaseRequests(pageSize = 10, pageIndex = 1) {
    return axios.get(
      GlobalValues.endPointUrl +
        `/purchaseRequests?pageSize=${pageSize}&pageIndex=${pageIndex}`
    );
  }

  static deletePurchaseRequest(id) {
    return axios.delete(GlobalValues.endPointUrl + `/purchaseRequests/${id}`);
  }

  // Links Sync
  static getSyncLinks(q = "", pageIndex = 1, pageSize = 10) {
    return axios.get(
      GlobalValues.endPointUrl +
        `/linksSync?q=${q}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  static addSyncLink(body) {
    return axios.post(GlobalValues.endPointUrl + `/linksSync`, body);
  }

  static deleteSyncLink(id) {
    return axios.delete(GlobalValues.endPointUrl + `/linksSync/${id}`);
  }
  //OperationScheduler?orderBy=asc&pageSize=10&pageIndex=1
  static getOperationScheduler(pageSize = 10, pageIndex = 1, orderBy = "asc") {
    return axios.get(
      GlobalValues.endPointUrl +
        `/OperationScheduler?orderBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}`
    );
  }

  static getOperationTokens(pageSize = 10, pageIndex = 1, orderBy = "asc") {
    return axios.get(
      GlobalValues.endPointUrl +
        `/OperationScheduler/tokens?orderBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}`
    );
  }

  static addOperationItem(obj) {
    return axios.post(GlobalValues.endPointUrl + `/OperationScheduler`, obj);
  }

  static uploadOperationTokens(obj) {
    return axios.post(
      GlobalValues.endPointUrl + `/OperationScheduler/tokens/upload`,
      obj
    );
  }

  static deleteOperationItem(objId) {
    return axios.delete(
      GlobalValues.endPointUrl + `/OperationScheduler/${objId}`
    );
  }

  static deleteExpiredOperationsTokens() {
    return axios.delete(
      GlobalValues.endPointUrl + `/OperationScheduler/token/deleteExpiredTokens`
    );
  }

  static checkAllOperationsTokens() {
    return axios.post(
      GlobalValues.endPointUrl + `/OperationScheduler/token/check-all`
    );
  }

  static checkOperationAccessToken(tokenId) {
    return axios.post(
      GlobalValues.endPointUrl + `/OperationScheduler/tokens/check/${tokenId}`
    );
  }

  static deleteOperationToken(objId) {
    return axios.delete(
      GlobalValues.endPointUrl + `/OperationScheduler/tokens/${objId}`
    );
  }

  static resetOperationToken() {
    return axios.delete(
      GlobalValues.endPointUrl + `/OperationScheduler/token/reset`
    );
  }
}
