import "./App.css";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Layout } from "antd";
import { Sidebar } from "./layout/sidebar";
import Dashboard from "./components/dashboard/Dashboard";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ManageTokens } from "./components/accessTokens/manageTokens";
import { AddAccessToken } from "./components/accessTokens/addAccessToken";
import { ManageObjects } from "./components/objects/ManageObjects";
import { AddObject } from "./components/objects/AddObject";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { Login } from "./layout/Login";
import { EventLog } from "./components/tables/EventLog";
import { ProvideAuth } from "./auth/UseAuth";
import { PurchaseRequests } from "./components/tables/PurchaseRequests";
import { LinksSync } from "./components/links-sync/LinksSync";
import { OperactionScheduler } from "./components/operation-scheduler/OperationScheduler";

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <Sidebar />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{ paddingLeft: "40px" }}
            >
              <h3>Like Story Tokens Tool </h3>
            </Header>
            <Content style={{ margin: "0 16px" }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360, margin: "24px 16px" }}
              >
                <Switch>
                  <Route path={"/login"} exact>
                    <Login />
                  </Route>
                  <ProtectedRoute path="/dashboard">
                    <Dashboard />
                  </ProtectedRoute>
                  <ProtectedRoute path="/tokens/add" exact={true}>
                    <AddAccessToken />
                  </ProtectedRoute>
                  <ProtectedRoute path="/tokens">
                    <ManageTokens />
                  </ProtectedRoute>
                  <ProtectedRoute path="/objects/:id">
                    <AddObject />
                  </ProtectedRoute>
                  <ProtectedRoute path="/objects">
                    <ManageObjects />
                  </ProtectedRoute>
                  <ProtectedRoute path="/logs">
                    <EventLog />
                  </ProtectedRoute>
                  <ProtectedRoute path="/purchase-requests">
                    <PurchaseRequests />
                  </ProtectedRoute>
                  <ProtectedRoute path="/links-sync">
                    <LinksSync />
                  </ProtectedRoute>
                  <ProtectedRoute path="/operation-scheduler">
                    <OperactionScheduler />
                  </ProtectedRoute>
                  <ProtectedRoute path={"*"}>
                    <Redirect to={"/dashboard"} />
                  </ProtectedRoute>
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Copyright &copy; Like Story Team 2021
            </Footer>
          </Layout>
        </Layout>
      </Router>
    </ProvideAuth>
  );
}

export default App;
