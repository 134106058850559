import {Card, Col, Row, Skeleton, Statistic} from "antd";

export function DashboardCards({data, loadingState}) {


    const typesColor = {
        primary: "#1890ff",
        danger: "#ff4d4f",
        success: "#52c41a",
        warning: "#faad14",
        magenta: '#c41d7f'
    }
    return (
        <Row gutter={[16,16]}>
            {data.map(itm =>
                <Col span={4}>
                    <Card className="site-statistic-card" type={itm.type | "primary"}>
                        <Skeleton paragraph={{rows: 1}} active loading={loadingState}>
                            <Statistic title={itm.title} value={itm.value} valueStyle={{color: typesColor[itm.type]}}/>
                        </Skeleton>
                    </Card>
                </Col>
            )}
        </Row>

    )
}