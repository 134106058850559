import {useEffect, useState} from "react";
import Repository from "../../helpers/Repository";
import {Col, message, Popconfirm, Row, Spin, Table, Tooltip} from "antd";
import Text from "antd/es/typography/Text";
import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons";
import {Helpers} from "../../shared/Helper";

export function PurchaseRequests() {
    Helpers.setTitle("Purchase Requests");
    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        getRequests(1, 10);
        console.log("log")
    }, [])

    const getRequests = (pageIndex, pageSize) => {
        setIsLoading(true);
        setPageSize(pageSize);
        Repository.getAllPurchaseRequests(pageSize, pageIndex).then(res => {
            setTotalCount(res.data.totalCount);
            setRequests(res.data.data);
            console.log(res);

        })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }

    const deleteToken = id => {
        const key = Helpers.makeKey(16);
        message.loading({content: "Deleting Purchase Request, please wait...", duration: 0, key}).then();
        Repository.deletePurchaseRequest(id)
            .then(res => {
                message.success({content: "Purchase Request deleted successfully!", key}).then();
                const newRequests = requests.filter(a => a.id !== id);
                setRequests(newRequests);
                setTotalCount(totalCount - 1);
            })
            .catch(err => {
                console.log(err);
                message.error({content: "Unexpected error happened", key}).then();
            })
    };

    const renderActions = (val, row) =>
        <Row gutter={[24, 24]}>
            {/*Delete Token*/}
            <Col span={6}>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteToken(row.id)}
                >
                    <Tooltip title={"Delete Request"}>
                        <Text type={"danger"} style={{cursor: "pointer", fontSize: "18px"}}><DeleteOutlined/></Text>
                    </Tooltip>
                </Popconfirm>
            </Col>

        </Row>;


    const tableColumns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Count",
            dataIndex: "count",
            key: "count"
        },
        {
            title: "At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: date => <>{new Date(date).toLocaleString()}</>
        },
        {
            title: "Actions",
            dataIndex: '',
            key: "actions",
            render: renderActions
        }
    ];

    return (
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
            <Table
                columns={tableColumns}
                dataSource={requests}

                pagination={
                    {
                        showSizeChanger: true,
                        pageSize: pageSize,
                        total: totalCount,
                        pageSizeOptions: [1, 5, 10, 20, 50, 100],
                        onChange: getRequests,
                        showTotal: t => `Total ${t} items`
                    }
                }
            />
        </Spin>
    )
}