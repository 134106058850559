import {useEffect, useState} from "react";
import {DashboardCards} from "./DashboardCards";
import {Col, Row} from "antd";
import {LinksItems} from "../tables/LinksItems";
import Title from "antd/es/typography/Title";
import Repository from "../../helpers/Repository";
import {TokenItems} from "../tables/TokenItems";
import {Helpers} from "../../shared/Helper";
import {useAuth} from "../../auth/UseAuth";

export default function Dashboard() {
    const {user} = useAuth();
    const [totalTokensCount, setTotalTokensCount] = useState(0);
    const [workingTokensCount, setWorkingTotalTokens] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [processingItemsCount, setProcessingItemsCount] = useState(0);

    const [successItemsCount, setSuccessItemsCount] = useState(0);
    const [cancelledItemsCount, setCancelledItemsCount] = useState(0);
    const [items, setItems] = useState([]);
    const [tokenItems, setTokenItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCardLoading, setIsCardLoading] = useState(false);
    const [isTokensLoading, setIsTokensLoading] = useState(false);


    useEffect(() => {
        getLinks(1, 5, "desc");
        getTokens(1, 5, "desc");
        getStatistics();

    }, []);

    Helpers.handleNotification("statisticsNotification", res => {

        setStatistics(res);
    });

    Helpers.handleNotification("taskStatusChanged", res => {
        const newItems = [...items];
        const index = newItems.findIndex(a => a.id === res.id);
        if (index === -1)
            return;
        newItems[index] = res;
        setItems(newItems);
    });

    const setStatistics = (obj) => {
        setTotalTokensCount(obj.totalTokens);
        setWorkingTotalTokens(obj.totalWorkingToken);
        setTotalItemsCount(obj.totalObjects);
        setProcessingItemsCount(obj.processingItems);
        setSuccessItemsCount(obj.successItems);
        setCancelledItemsCount(obj.cancelledItems);
    }

    Helpers.setTitle("Dashboard");

    const cardData = [
        {
            title: "Total Tokens",
            value: totalTokensCount,
            type: "primary"
        },
        {
            title: "Working Tokens",
            value: workingTokensCount,
            type: "success"
        },
        {
            title: "Total Objects",
            value: totalItemsCount,
            type: "primary"
        },
        {
            title: "Processing Objects",
            value: processingItemsCount,
            type: "warning"
        },
        {
            title: "Success Objects",
            value: successItemsCount,
            type: "success"
        },
        {
            title: "Cancelled Objects",
            value: cancelledItemsCount,
            type: "danger"
        }
    ];

    const getLinks = (pageIndex, pageSize, orderBy = "asc") => {
        setIsLoading(true);
        Repository.getAllLinks(pageSize, pageIndex, orderBy,).then(res => {
            console.log(res.data);
            setItems(res.data.data);
            setTotalItemsCount(res.data.totalCount);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const getLinksDesc = (pageIndex, pageSize) => {
        getLinks(pageIndex, pageSize, "desc");
    }
    const getTokensDesc = (pageIndex, pageSize) => {
        getTokens(pageIndex, pageSize, "desc");
    }
    const getTokens = (pageIndex, pageSize, orderBy = "asc") => {
        setIsTokensLoading(true);
        Repository.getAccessTokens(pageSize, pageIndex, orderBy).then(res => {
            console.log(res.data);
            setTokenItems(res.data.data);
            setTotalTokensCount(res.data.totalCount);
            setIsTokensLoading(false);
        }).catch(err => {
            console.log(err);
            setIsTokensLoading(false);
        });
    }

    const getStatistics = () => {
        setIsCardLoading(true);
        Repository.getStatistics().then(res => {
            console.log(res.data);
            setStatistics(res.data);
            setIsCardLoading(false);
        }).catch(err => {
            console.log(err);
            setIsCardLoading(false);
        });
    }


    return (
        <Row gutter={[24,24]}>
            <Col span={24}>
                <DashboardCards data={cardData} loadingState={isCardLoading}/>
            </Col>
            <Col span={24}>
                <Title level={3}>Last 5 Objects</Title>
                <LinksItems data={items} totalItems={totalItemsCount >= 5 ? 5 : totalItemsCount}
                            loadingState={isLoading} onChange={getLinksDesc}/>
            </Col>
            <Col span={24}>
                <Title level={3}>Last 5 Access Tokens</Title>
                <TokenItems accessTokens={tokenItems} totalItems={totalTokensCount >= 5 ? 5 : totalTokensCount}
                            loadingState={isTokensLoading} onChange={getTokensDesc}/>
            </Col>
        </Row>
    )
}