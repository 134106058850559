import { Input, Space } from "antd";

export const LinkSyncSearch = ({ onSearch }) => {
  return (
    <Space>
      <Input.Search
        allowClear
        placeholder="Enter URL to search"
        onSearch={onSearch}
      />
    </Space>
  );
};
