import Sider from "antd/es/layout/Sider";
import { Menu, notification } from "antd";
import {
  ApiOutlined,
  CloudSyncOutlined,
  PieChartOutlined,
  SolutionOutlined,
  SwapOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useAuth } from "../auth/UseAuth";
import { Helpers } from "../shared/Helper";

export function Sidebar() {
  const { isAuthenticated } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    console.log(collapsed);
    setCollapsed(collapsed);
  };

  if (isAuthenticated) {
    Helpers.handleNotification("taskCompleted", (res) => {
      notification.success({
        duration: 5,
        message: `Object completed`,
        description: `Object with id '${res.id}' has completed successfully!`,
        key: Helpers.makeKey(18),
        placement: "bottomRight",
      });
    });
  }

  return (
    <>
      {isAuthenticated && (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              Dashboard
              <Link to="/dashboard" />
            </Menu.Item>
            <Menu.Item key="2" icon={<UsergroupAddOutlined />}>
              Access Tokens
              <Link to="/tokens" />
            </Menu.Item>
            <Menu.Item key="3" icon={<ApiOutlined />}>
              Objects
              <Link to="/objects" />
            </Menu.Item>
            <Menu.Item key="4" icon={<SolutionOutlined />}>
              Event Log
              <Link to="/logs" />
            </Menu.Item>
            <Menu.Item key="5" icon={<UserAddOutlined />}>
              Purchase Requests
              <Link to="/purchase-requests" />
            </Menu.Item>
            <Menu.Item key="6" icon={<CloudSyncOutlined />}>
              Links Sync
              <Link to="/links-sync" />
            </Menu.Item>
            <Menu.Item key="7" icon={<SwapOutlined />}>
              Operation Scheduler
              <Link to="/operation-scheduler" />
            </Menu.Item>
          </Menu>
        </Sider>
      )}
    </>
  );
}
