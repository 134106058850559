import {Button, Col, notification, Row, Space} from "antd";
import {DashboardCards} from "../dashboard/DashboardCards";
import {useEffect, useState} from "react";
import Repository from "../../helpers/Repository";
import {Link, useRouteMatch} from "react-router-dom";
import {LinksItems} from "../tables/LinksItems";
import {Helpers} from "../../shared/Helper";

export function ManageObjects() {
    Helpers.setTitle("Manage Objects");
    const [isCardsLoading, setIsCardsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [totalObjectCount, setTotalObjectsCount] = useState(0);
    const [totalProcessingItemsCount, setTotalProcessingItemsCount] = useState(0);
    const [totalSuccessItemsCount, setTotalSuccessItemsCount] = useState(0);
    const [totalCancelledItemsCount, setTotalCancelledItemsCount] = useState(0);
    const [items, setItems] = useState([]);

    const cardData = [
        {
            title: "Total Objects",
            value: totalObjectCount,
            type: "primary"
        },
        {
            title: "Processing Objects",
            value: totalProcessingItemsCount,
            type: "warning"
        },
        {
            title: "Succeeded Objects",
            value: totalSuccessItemsCount,
            type: "success"
        },
        {
            title: "Cancelled Objects",
            value: totalCancelledItemsCount,
            type: "danger"
        }
    ];

    useEffect(() => {
        getStatistics();
        getLinks(1, 10);
    },[]);

    Helpers.handleNotification("statisticsNotification", res => {
        console.log(res);
        setStatistics(res);
    });

    Helpers.handleNotification("taskStatusChanged", res => {
        console.log(res, "TaskStatusChanged");
        const newItems = [...items];
        const index = newItems.findIndex(a => a.id === res.id);
        if (index === -1) {
            return;
        }
        newItems[index] = res;
        setItems(newItems);
    });

    Helpers.handleNotification("taskCompleted", res => {
        console.log(res, "TaskCompleted");
        const newItems = [...items];
        const index = newItems.findIndex(a => a.id === res.id);
        if (index === -1) {
            return;
        }
        newItems[index] = res;
        setItems(newItems);
        notification.success({
            duration: 5,
            message: "Object completed successfully!",
            key: Helpers.makeKey(18),
            placement: "bottomRight"
        });
    });

    const setStatistics = (obj) => {
        setTotalObjectsCount(obj.totalObjects);
        setTotalProcessingItemsCount(obj.processingItems);
        setTotalSuccessItemsCount(obj.successItems);
        setTotalCancelledItemsCount(obj.cancelledItems);
    }

    const getStatistics = () => {
        setIsCardsLoading(true);
        Repository.getStatistics().then(res => {
            console.log(res.data);
            setStatistics(res.data);
        }).catch(err => {
            console.log(err);

        })
            .finally(() => {
                setIsCardsLoading(false);
            });
    }

    const getLinks = (pageIndex, pageSize, orderBy = "desc") => {
        setIsDataLoading(true);
        Repository.getAllLinks(pageSize, pageIndex, orderBy,).then(res => {
            console.log(res.data);
            setItems(res.data.data);
            setTotalObjectsCount(res.data.totalCount);
            setIsDataLoading(false);
        }).catch(err => {
            console.log(err);
            setIsDataLoading(false);
        });
    }


    const {url} = useRouteMatch();
    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <DashboardCards data={cardData} loadingState={isCardsLoading}/>
            </Col>
            <Col span={24}>
                <Space style={{width: '100%'}}>
                    <Link to={`${url}/add`} className={"ant-btn ant-btn-primary"}>
                        Add New Object
                    </Link>
                </Space>

            </Col>
            <Col span={24}>
                <LinksItems data={items} totalItems={totalObjectCount} loadingState={isDataLoading}
                            onChange={getLinks}/>
            </Col>
        </Row>
    )
}