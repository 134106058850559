import {Form, Input, Button, Row, Col, Select, message} from 'antd';

import {Option} from "antd/es/mentions";
import Repository from "../../helpers/Repository";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {Helpers} from "../../shared/Helper";


export function AddAccessToken() {
    Helpers.setTitle("New Access Token");
    const history = useHistory();
    const [form] = Form.useForm();
    const [isSubmitDisabled, setIsSubmitDisable] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = () => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        const values = form.getFieldsValue();
        console.log(values)
        const obj = {
            username: values.username,
            token: values.token,
            isReactionToken: values.isReactionToken === "_" ? null : values.isReactionToken === 'true'
        }
        console.log(
            obj
        )
        const key = Helpers.makeKey(12);
        //setIsSubmitDisable(true);
        message.loading({content: "Adding new token, please wait...", key, duration: 0});
        Repository.AddAccessToken(obj).then(res => {
            message.success({content: "Token added successfully!", key});
            history.push("/tokens");
        }).catch(e => {
            console.log(e);
            message.error({content: "Unexpected error occurred.", key});
        }).finally(() => {
            setIsSubmitting(false);
        });

    }

    const onChange = _ => {
        const values = form.getFieldsValue();
        if (values.token === '' || values.isReactionToken === '') {
            setIsSubmitDisable(true)
        } else {
            setIsSubmitDisable(false);
        }

    }

    return (

        <Row gutter={[24, 24]}>
            <Col span={12}>
                <Form
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    onChange={onChange}
                >

                    <Form.Item label="Username" name="username">
                        <Input placeholder="Access Token account user name"/>
                    </Form.Item>
                    <Form.Item
                        label="Access Token"
                        name="token"
                        rules={[
                            {
                                required: true,
                                message: "Access Token is required"
                            }
                        ]}
                        hasFeedback
                    >
                        <Input placeholder="Access Token"/>
                    </Form.Item>
                    <Form.Item label={"Token Type"} name={"isReactionToken"}
                               rules={[{
                                   required: true,
                                   message: "Token type is required."
                               }]}
                               initialValue={"_"}
                    >
                        <Select placeholder="Select Reaction Type">
                            <Option value="_">Method 3 - (All)</Option>
                            <Option value="true">Method 2 (Reaction)</Option>
                            <Option value="false">Method 1 (Like, Follow)</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button type="primary" onClick={submit}
                                loading={isSubmitting}
                                disabled={isSubmitDisabled}

                        >
                            Add Token
                        </Button>
                    </Form.Item>

                </Form>
            </Col>

        </Row>

    )
}