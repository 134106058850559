import {HubConnectionBuilder} from "@aspnet/signalr";
import GlobalValues from "../Constants/GlobalValues";

export class Helpers {

    static setTitle(title)
    {
        document.title = title + " | Like Story Token Tool";
    }

    static makeKey(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }


    static startConnection(token) {
        if (!this.connection) {
            console.log("Establishing connection...")
            this.connection = new HubConnectionBuilder()
                .withUrl(GlobalValues.signalREndPoint, {
                    accessTokenFactory() {
                        return token
                    }
                })
                .build();

            this.connection.start().then(() => {
                console.log("Connected");
            }).catch(err => console.log(err));
        }
    }

    static connection;


    static isStarted = false;
    static events = [];

    static handleNotification(event, callback) {
        this.startConnection(localStorage.getItem("userToken"));
        if (this.connection) {
            this.connection.off(event);
            this.connection.on(event, res => {
                callback(res);
            });
        }
    }

}